<template>
  <auth-card
    :loading="loading"
    @submit="resetPassword"
  >

    <template #title>
      Reset Password 🔒
    </template>

    <template #subtitle>
      Your new password must be different from previously used passwords
    </template>

    <template #form>

      <!-- password -->
      <form-validation-password-input
        v-model="form.password"
        vid="Password"
      />

      <!-- confirm password -->
      <form-validation-password-input
        v-model="form.password_confirmation"
        name="Confirm Password"
        rules="required|confirmed:password"
        label="Confirm Password"
      />

    </template>

    <template #button-text>
      Set New Password
    </template>

    <template #footer>
      <b-link :to="{ name:'auth-login' }">
        <feather-icon icon="ChevronLeftIcon" /> Back to login
      </b-link>
    </template>

  </auth-card>
</template>

<script>
import {
  BLink,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FormValidationPasswordInput from '@/components/forms/validation/FormValidationPasswordInput.vue'
import AuthCard from '@/components/auth/AuthCard.vue'

export default {
  components: {
    BLink,
    AuthCard,
    FormValidationPasswordInput,
  },
  data() {
    return {
      form: {
        token: this.$route.params.token,
        email: this.$route.query.email,
        password: '',
        password_confirmation: '',
      },
      loading: false,
      // validation
      required,
    }
  },
  methods: {
    resetPassword() {
      this.loading = true

      this.$http.post('/auth/reset-password', this.form)
        .then(() => {
          this.$router.replace({ name: 'auth-login' })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Your password has been reset!',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
            })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Error! ${error.response.data.message}`,
              icon: 'XOctagonIcon',
              variant: 'danger',
            },
          })
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => this.loading = false)
    },
  },
}
</script>
